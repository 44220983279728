<template>
  <div style="padding: 20px">
    <h2>（一）图文问诊权益服务</h2>
    <p>
      <u><strong>【服务特点】</strong></u>
    </p>
    <p>全天服务、专业耐心、全科咨询</p>
    <p>
      <u><strong>【什么是图文问诊服务】</strong></u>
    </p>
    <p>
      图文问诊服务是一项便捷、高效的医疗健康服务，为用户提供7*24小时全天候、全方位的专业医生咨询和治疗建议服务。
    </p>
    <p>【权益说明】</p>
    <p><br /></p>
    <table style="width: auto">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">序号</td>
          <td colSpan="1" rowSpan="1" width="117">服务权益</td>
          <td colSpan="2" rowSpan="1" width="343">权益明细</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">1</td>
          <td colSpan="1" rowSpan="1" width="117">三甲极速图文问诊</td>
          <td colSpan="2" rowSpan="1" width="343">
            专业公立三甲医院医生在线极速响应，最快可30秒内接诊，医生根据用户身体状况给予相应的解决方案，问诊形式为图文，每次问诊服务时长为30分钟
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">2</td>
          <td colSpan="1" rowSpan="1" width="117">二次诊疗意见</td>
          <td colSpan="1" rowSpan="1" width="343">
            用户可以将在医院就诊的病历信息上传问诊对话中，由专业医生详细解释病历中的内容，并提醒需要注意的问题
          </td>
          <td colSpan="1" rowSpan="1" width="0"></td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">3</td>
          <td colSpan="1" rowSpan="1" width="117">用药咨询</td>
          <td colSpan="1" rowSpan="1" width="343">
            专业医师一站式用药咨询、建议、指导、开药（购药费用自理），用户可在服务有效期间进行不限次用药咨询
          </td>
          <td colSpan="1" rowSpan="1" width="0"></td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">4</td>
          <td colSpan="1" rowSpan="1" width="117">测评</td>
          <td colSpan="1" rowSpan="1" width="343">
            包含中医体质测评、心理测评、AI皮肤等30+健康测评，方便快速了解身心健康状况
          </td>
          <td colSpan="1" rowSpan="1" width="0"></td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">5</td>
          <td colSpan="1" rowSpan="1" width="117">送药上门</td>
          <td colSpan="1" rowSpan="1" width="343">
            医生处方后药品可依据用户需求选择剂型即自煎、代煎、颗粒冲服，线上购药可享受免费送药上门服务
          </td>
          <td colSpan="1" rowSpan="1" width="0"></td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">6</td>
          <td colSpan="1" rowSpan="1" width="117">电子档案</td>
          <td colSpan="1" rowSpan="1" width="343">
            建立随时可查看的动态电子健康档案、并协助用户及时更新健康情况及就医信息
          </td>
          <td colSpan="1" rowSpan="1" width="0"></td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">7</td>
          <td colSpan="1" rowSpan="1" width="117">AI体检报告解读</td>
          <td colSpan="1" rowSpan="1" width="343">
            用户可将体检报告进行上传，系统会依据体检报告的相应结果进行快速分析，并将分析结果生成可视化看板，以便用户快速理解自身健康状况
          </td>
          <td colSpan="1" rowSpan="1" width="0"></td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="46">8</td>
          <td colSpan="1" rowSpan="1" width="117">节气健康科普</td>
          <td colSpan="1" rowSpan="1" width="343">
            提供24篇时令养生科普文章/视频
          </td>
          <td colSpan="1" rowSpan="1" width="0"></td>
        </tr>
      </tbody>
    </table>
    <p><br /></p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
